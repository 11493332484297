@import '~@progress/kendo-theme-default/scss/button/_index.scss';
@import '~@progress/kendo-theme-default/scss/grid/_index.scss';
@import '~@progress/kendo-theme-default/scss/splitter/_index.scss';
@import '~@progress/kendo-theme-default/scss/input/_index.scss';
@import '~@progress/kendo-theme-default/scss/checkbox/_index.scss';
@import '~@progress/kendo-theme-default/scss/calendar/_index.scss';
//@import '~@progress/kendo-theme-default/scss/datetime/_index.scss';
@import '~@progress/kendo-theme-default/scss/dropdownlist/_index.scss';
@import '~@progress/kendo-theme-default/scss/autocomplete/_index.scss';
@import '~@progress/kendo-theme-default/scss/dataviz/_index.scss';
@import '~@progress/kendo-theme-default/scss/tooltip/_index.scss';
@import '~@progress/kendo-theme-default/scss/popup/_index.scss';
@import '~@progress/kendo-theme-default/scss/numerictextbox/_index.scss';
@import '~@progress/kendo-theme-default/scss/textarea/_index.scss';
@import '~@progress/kendo-theme-default/scss/common/_index.scss';
@import '~@progress/kendo-theme-default/scss/upload/_index.scss';
@import '~@progress/kendo-theme-default/scss/loader/_index.scss';

.k-widget.k-splitter {
  background-color: rgba(236, 238, 239, 0.5);
}

.k-splitbar {
  box-shadow: 5px 5px 5px rgba(black, 0.2);
  background: white;
}

.k-splitbar-draggable-vertical .k-resize-handle {
  display: none;
}

.k-pane:first-child {
  background-color: white;
}

.k-grid th.k-header {
  color: $text-light;
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
  font-size: 16px!important;
  color: #0C2556!important;
  height: 48px;
  background-color: #ffffff;
}

.k-splitbar {
  &:active {
    background-color: transparent;
  }
}

.dropdown-icon-before {
  border-radius: 4px;
}
.dropdown-icon-before .k-select {
  order: -1;
}

.k-animation-container {
  display: block;
}
.popup-animation {
  z-index: 107;
}
.k-child-animation-container {
  display: inline-block;
}
.k-calendar.k-calendar-infinite .k-button.k-next-view,
.k-calendar.k-calendar-infinite .k-button.k-prev-view {
  display: none;
}

.k-master-row {
  height: 56px;
}

.k-master-row .k-grid-content-sticky,
.k-alt-row .k-grid-content-sticky {
  background: white;
  opacity: 1;
  z-index: 106;
  border-bottom: 1px solid #dee2e6;
}

.k-grid .k-master-row:hover .k-grid-content-sticky,
.k-grid .k-alt-row:hover .k-grid-content-sticky {
  background-color: #f0f0f1;
}

.k-grid .k-master-row.k-state-selected .k-grid-content-sticky {
  background-color: #cadef5;
}

.k-grid .k-master-row.k-state-selected:hover .k-grid-content-sticky {
  background-color: #bfd3eb;
}
