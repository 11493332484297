
@import './variables';
@import './bootstrap';
@import './typography';
@import './kendo';

body {
  font-family: $roboto;
  overflow-x: hidden;
  background-color: #f6f6f6;
}

main {
  background-color: #f6f6f6; //rgba(236, 238, 239, 0.5);
}

.add-new .k-select {
  display: none;
}

a:hover {
  text-decoration: none;
}

a.link-no-style {
  color: #fff;
  text-decoration: none;
}
a.link-no-styles {
  text-decoration: none;
}

.no-click-events {
  pointer-events: none;
}

.result-header {
  padding: 21px 0 21px 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
}

.result-content {
  padding: 30px 113px 0 113px;
}

.grid-style {
  height: 450px;
  font-family: $roboto;
  font-size: 16px;
}

.color-if-true {
  color: #0846A6;
}

.canvas-wrapper {
  width: 100%;
  display: flex;
  height: 1200px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.left-panel {
  background-color: #f6f6f6;
  margin-top: 6px;
  padding: 28px 34px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
}

.button-group {
  Button {
    height: 40px;
    margin-left: 24px;
    padding: 11px 12px;
  }
}

.group-container {
  border-radius: 4px;
  background: white;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 12px;
  padding-top: 16px;
}

.group-header {
  color: $primary;
  border-bottom: solid 1px #c3c5c9;
  margin-bottom: 0;
  font-size: 18px;
  padding-bottom: 14px;
  text-align: left;
}

.group-element {
  .k-form-field {
    flex: 1;
  }
}

.confidence-box {
  height: 39px;
  width: 35px;
  border-radius: 4px;
  margin-left: 10px;
  margin-top: 41px;
}

.result-header {
  .header-text {
    color: $contrast;
  }
}

.roboto-medium {
  font-weight: 500;
}

h1 {
  font-size: 24px;
  padding: 21px 0 21px 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
}

.pager {
  span {
    color: $contrast;
    margin-left: 16px;
    margin-right: 16px;
  }
  .btn-pager {
    height: 40px;
    width: 40px;
    border: solid 1px #c3c5c9 !important;
    font-size: 32px;
  }
}
